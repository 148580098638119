@import 'colors';
@import 'typography';

.navbar {
    padding-top: 32px;
    padding-bottom: 32px;
}

.navbar-nav li {
    padding-left: 1rem;
}

.navbar-nav a {
    color: white;
    font-family: $body;
    font-weight: 600;
}