@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Finlandica:wght@400;600;700&display=swap');

@import 'colors';

$body: 'Source Sans Pro', sans-serif;
$taglines: 'Finlandica', sans-serif;

.big-headline {
    font-family: $taglines;
    font-weight: 700;
    font-size: 8rem;
}

.headline-subtitle {
    font-family: $body;
    font-weight: 700;
    font-size: 1.5rem;
}

h3 {
    font-family: $taglines;
    font-weight: 600;
    font-size: 2rem;
    color: $dark;
}

h4 {
    font-family: $body;
    font-weight: 400;
    font-size: 1rem;
    color: $dark;
}

.one {
    position: absolute;
    color: rgba($color: #FF5757, $alpha: 0.7);
}

.two {
    position: absolute;
    left: 20px;
    top: 6px;
    color: rgba($color: #ffffff, $alpha: 0.4);
}

.three {
    position: absolute;
    left: 7px;
    top: 1px;
    color: rgba($color: #ffffff, $alpha: 0.3);
}

.quote-indicator {
    font-family: $body;
    font-weight: 800;
    font-size: 2rem;
    color: $dark;
}

.work-title {
    font-family: $taglines;
    font-weight: 700;
    font-size: 1.5rem;
}

.pill-title {
    font-family: $body;
    font-weight: 600;
    font-size: 0.75rem;
}

.cta-hint {
    font-family: $taglines;
    font-weight: 700;
    font-size: 2rem;
    color: white;
}