@import 'colors';
@import 'typography';

.main-banner {
    width: 100%;
    padding: 1rem;
}

.main-banner .container-fluid {
    width: 100%;
    height: 960px;
    background-color: $wave_dark;
    border-radius: 2.5rem;
    background: url('../resources/banner-bg.png');
    background-position: left top;
    background-size: cover;    
}

.banner-content {
    margin-top: 10rem;
    color: white;
    text-align: center;
}

.tagline {
    position: relative;
}

.call-to-action {
    margin-top: 180px;
}

.section-title {
    padding-top: 5rem;
    padding-bottom: 2rem;
    text-align: center;
}

.partners-slider {
    text-align: center;
}

.process {
    margin-top: 1rem;
    text-align: center;
}

.step {
    height: 224px;
    background: url('../resources/step_o.svg');
    background-size: cover;
    display: flex;
    align-items: center;
}

.step-description {
    width: 200px;
}

.separator {
    display: flex;
    align-items: center;
    height: 224px;
}

.process-disclaimer {
    padding: 1rem;
    margin-top: 3rem;
    text-align: center;
    background-color: $light-gray;

    border-radius: 2.5rem;
    font-family: $body;
    font-weight: 400;
}

.portfolio-image {
    border-radius: 1rem;
    overflow: hidden;
}

.zoom {
    transition: transform .2s; /* Animation */
}
  
.zoom:hover {
    transform: scale(1.15); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.pill {
    background-color: $otw_orange;
    color: white;
    border-radius: 30px;
}

.feedback {
    padding: 3rem;
    background-color: $light-gray;
}

.feedback-avatar {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: red;
}

.contacts {
    padding: 3rem;
    background-color: $wave_dark;
}

footer {
    padding: 1rem;
    background-color: $wave_indigo;
    color: white;
    font-family: $body;
    font-weight: 400;
    font-size: 0.75rem;
    text-align: center;
}

.btn-cta-primary {
    background-color: $otw_orange;
    color: white;
    border-radius: 100px;
    padding-left: 1rem;
    padding-right: 1rem;

    font-family: $body;
    font-weight: 600;
}

.btn:active {
    background-color: $otw_orange;
    color: white;
}

.btn:hover {
    background-color: $otw_orange;
    color: white;
    border-style: dashed;
}

.btn:focus {
    background-color: $otw_orange;
    color: white;
    border-style: dashed;
}

/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 480px) {
    
    .call-to-action  {
        margin-top: 560px;
    }

    .separator {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
    }
}

@media (min-width:481px) {
    
    .call-to-action  {
        margin-top: 380px;
    }

}

@media (min-width:960px) {
    
    .call-to-action  {
        margin-top: 180px;
    }

}